import React from "react";
import main_data from "../Info/Main_data";
//
import '../Css/Main.css';

function BuyButton(){
    return(
        <div>
            {/** Redirection vers le quest store */}
            <div className="main-achat">
                
                <a href={main_data[0].url} target="blank" rel="noreferrer">
                    <button id="button-achat" className="btn bg-danger bg-gradient">
                        {main_data[0].text_button}
                    </button>
                </a>

            </div>
        </div>
    );
}

export default BuyButton;
