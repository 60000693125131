import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ScrollToTop from './ScrollToTop';
import {HashRouter} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>

    <HashRouter>
      <ScrollToTop/>
      <App />

    </HashRouter>

  </React.StrictMode>

);

// TELMINDUSTRIE