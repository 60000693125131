import React from "react";
import { NavLink } from "react-router-dom";
import footer_data from "../Info/Footer_data";
//
import "../Css/Footer.css";

function Footer(){
    return(

        <div className="footer-main">

            <div>

                <NavLink to='/privacy' className={"footer-link"}>Privacy Policy</NavLink>

                <a 
                    href={footer_data[0].email}
                    className={"footer-link"}>
                        Support
                </a>

            </div>
            
        </div>

    );
}

export default Footer;
