import React from "react";
import social_media from "../Info/Header_data";
//
import "../Css/Header.css";

function HeaderLinks(){
    return(
        <div>
            <div className="header-links">
                
                <ul className="link-list">

                    {social_media.map(
                        (icon)=> 
                            <div>

                                <li className="link-items">
                                    <a href={icon.url} target="blank" rel="noreferrer">
                                        <img src={icon.icon} alt={icon.name} className="Icon" draggable={"false"}/>
                                    </a>
                                </li>

                            </div>
                        )
                    }
                    
                </ul>

            </div>
        </div>
    );
}

export default HeaderLinks;
