// Données des images promo

const imgData = [
    {
        src : "./Images/descImages/ImgN1.webp",
        nom : "Image1",
        class : "section-1",
        description : "Explore a lively and open city by rope swinging, jumping, and climbing walls. Don't be scared of heights!"
    },
    {
        src : "./Images/descImages/ImgN2.webp",
        nom : "Image2",
        class : "section-2",
        description : "Choose the time of day you want to fight crime: day, night, evening and dusk."
    },    {
        src : "./Images/descImages/ImgN3.webp",
        nom : "Image3",
        class : "section-3",
        description : "Handle weapons realistically. Disarm your enemies or give them a taste of their own medicine!"
    },    {
        src : "./Images/descImages/ImgN4.webp",
        nom : "Image4",
        class : "section-4",
        description : "Take down criminals in countless creative ways, including grabbing, shooting, punching, disarming, or dropping them from the tallest skyscraper..."
    },    {
        src : "./Images/descImages/ImgN5.webp",
        nom : "Image5",
        class : "section-5",
        description : "Use a variety of weapons to take down your enemies: fists, pistols, assault rifles, large props, baseball bats, explosives, and even frying pans."
    },
]

export default imgData;
