// Données des nouvelles

const newsData = [
    {
        update: 1,
        title: "Release Update",
        blogLink: "",
        date: "08/18/2023",
        backgroundImg: "./Images/updatesImg/update1.webp",
    },
    {
        update: 2,
        title: "Npc Overhaul",
        blogLink: "https://www.oculus.com/experiences/quest/developer-post/294940880019775/",
        date: "10/24/2023",
        backgroundImg: "./Images/updatesImg/update2.webp",
    },
    {
        update: 3,
        title: "Stabbing & More",
        blogLink: "https://www.oculus.com/experiences/quest/developer-post/696608922447058/",
        date: "12/06/2023",
        backgroundImg: "./Images/updatesImg/update3.webp",
    },
    {
        update: 4,
        title: "Stealth Missions",
        blogLink: "https://www.oculus.com/experiences/quest/developer-post/1298132174473849/",
        date: "03/13/2024",
        backgroundImg: "./Images/updatesImg/update4.webp",
    },
]

export default newsData;
