// Données des liens

const social_media = [
    {
        name: "Youtube",
        url: "https://www.youtube.com/@BattleGlide",
        icon: "/Images/icons/youtube-icon.webp"
    },
    {
        name: "Facebook",
        url: "https://www.facebook.com/groups/3452610375007269",
        icon: "/Images/icons/facebook-icon.webp"
    },
    {
        name: "Twitter",
        url: "https://twitter.com/BattleGlideVR",
        icon: "/Images/icons/twitter-icon.webp"
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/battleglide/",
        icon: "/Images/icons/ig-icon.webp"
    },
    {
        name: "Tiktok",
        url: "https://www.tiktok.com/@battleglidevr",
        icon: "/Images/icons/tiktok-icon.webp"
    },
    {
        name: "Discord",
        url: "https://discord.gg/battleglide",
        icon: "/Images/icons/discord-icon.webp"
    },
]

export default social_media;
