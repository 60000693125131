import React from 'react';
import newsData from '../Info/News_data';
//
import "../Css/News.css";

function News(){

    return(

        <div className='news-main'>

            <div className='news-updates'>

            {newsData.map(
                (updates)=> 
                    <div className='update-block'
                        style={{background: `url(${updates.backgroundImg})`, backgroundSize: 'cover'}}
                    >

                        <a href={updates.blogLink} className='update-desc' target='blank'>
                            <div>
                                <h2 className='update-title'>{updates.title}</h2>
                                <h4 className='update-date'>{updates.date}</h4>
                            </div>
                        </a>

                    </div>
                )
            }

            </div>

        </div>
    );
}

export default News;
