import React from "react";
import { NavLink } from "react-router-dom";
import HeaderLinks from "../Classes/HeaderLinks";
import footer_data from "../Info/Footer_data";
import social_media from "../Info/Header_data";
//
import "../Css/Header.css";

function Header(){
    return(

        <div className="header-main">

            <div className="header-nav">

                <div className="bird-icon">

                    <li class="nav-item dropdown">

                        <a class="nav-link dropdown-toggle" href="http://battleglide.com" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={"Images/company/BirdNoText.png"} alt={"logo"} id="header-logo" draggable={"false"}/>
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><NavLink to={"/"} className={"nav-link dropdown-item"}>Home</NavLink></li>
                            <li><NavLink to={"/privacy"} className={"nav-link dropdown-item"}>Privacy Policy</NavLink></li>
                            <li><NavLink to={"/news"} className={"nav-link dropdown-item"}>What's New</NavLink></li>

                            {social_media.map(
                                (icon)=> 
                                    <div className="drop-link">

                                        <li className="dropdown-item">
                                            <a href={icon.url} target="blank" rel="noreferrer">
                                                {icon.name}
                                            </a>
                                        </li>

                                    </div>
                                )
                            }
                        </ul>

                    </li>

                </div>

                <div className="header-nav-link">
                    <NavLink to='/' className={"nav-link"}>Home</NavLink>
                    <NavLink to='/privacy' className={"nav-link"}>Privacy Policy</NavLink>
                    <NavLink to='/news' className={"nav-link"}>What's New</NavLink>
                    <a 
                        href={footer_data[0].email}
                        className={"nav-link"}>
                            Support
                    </a>

                </div>
                
            </div>

            <div className="header-links">
                <HeaderLinks/>
            </div>

        </div>

    );
}

export default Header;
