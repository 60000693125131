import React from "react";
import imgData from "../Info/Description_data";
//
import '../Css/Description.css';

function Description(){
    return(

        <div className="main-description">

            {imgData.map(
                (data)=>
                    <div className={data.class}>
                        <img
                            src={data.src}
                            alt={data.nom}
                            class="d-block w-100"
                            className="game-image"
                            draggable={"false"}
                        />

                        <p className="text-zone">
                            {data.description}
                        </p>
                    </div>
                )
            }

        </div>
    );
}

export default Description;
