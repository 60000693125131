import React from 'react';
import Description from "./Description";
import main_data from "../Info/Main_data";
import BuyButton from "../Classes/Button";
//
import "../Css/Main.css";

function Main(){

    return(

        <div className="main-main">

            <div className="top-section">



                <video id='img-promo' loop autoplay="" muted>
                    <source src={main_data[0].video_trailer} type="video/mp4"/>
                </video>


                <div className="top-button">
                    <BuyButton/>
                </div>
                
            </div>

            <div className="main-description">
                <Description/>
            </div>

        </div>
    );
}

export default Main;
