import React from "react";
import {Routes, Route} from 'react-router-dom';
import Header from "./Composants/Header";
import Main from "./Composants/Main";
import Footer from "./Composants/Footer";
import Privacy from "./Composants/PrivacyPolicy";
import News from "./Composants/News";
import Updates from "./Composants/Updates";
//
import "./Css/App.css"

function App() {
  return (

    <div className="App">

      <div className="app-header">
        <Header/>
      </div>

        <Routes>

          <Route path="/" Component={Main}/>
          <Route path="/privacy" Component={Privacy}/>
          <Route path="/news" Component={News}/>

        </Routes>

      <Footer/>

    </div>

  );
}

export default App;
